import { template as template_87b5b38c38db433982f5dbbffc281856 } from "@ember/template-compiler";
const FKControlMenuContainer = template_87b5b38c38db433982f5dbbffc281856(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_9da87e350287434eb2931801f7fd7da3 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_9da87e350287434eb2931801f7fd7da3(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;

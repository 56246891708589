import { template as template_dbd255975ee944cebabe6aee29761c48 } from "@ember/template-compiler";
const WelcomeHeader = template_dbd255975ee944cebabe6aee29761c48(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
